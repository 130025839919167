import { GlobalStyles } from "../GlobalStyles";
import ProductGoal from "../components/global/ProductGoal";
import RecentTasks from "../components/student/RecentTasks";
import { ReactComponent as ModuleIcon } from "../assets/icons/moduleicon.svg";
import { useContext, useEffect, useState } from "react";
import { supabase } from "../supabase";
import { AuthContext } from "../App";
import ModuleCard from "../components/student/ModuleCard";
import PuffLoader from "react-spinners/PuffLoader";

export default function Learn() {
    const { user } = useContext(AuthContext);
    const [modules, setModules] = useState([]);
    const [nextModule, setNextModule] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const { data: studentData, error: studentError } = await supabase
                .from("students")
                .select("linked_modules, studentgroups (uuid, modules)")
                .eq("email", user?.email)
                .single();
            if (studentError) {
                console.error("Error fetching student data:", studentError);
            } else {
                console.log("Student groups modules:", studentData.studentgroups.modules);

                let platform_modules = [];
                let staff_modules = [];
                studentData.studentgroups.modules.forEach((module) => {
                    if (isNaN(module)) {
                        staff_modules.push(module);
                        console.log("Staff module pushed:", module);
                    } else {
                        platform_modules.push(module);
                        console.log("Platform module pushed:", module);
                    }
                });

                const { data: modulesData, error: modulesError } = await supabase
                    .from("modules")
                    .select("*");

                if (modulesError) {
                    console.error("Error fetching modules:", modulesError);
                } else {
                    // Filter based on the presence of slides
                    const staffModules = modulesData.filter(module => module.slides && module.slides.length > 0);
                    const platformModules = modulesData.filter(module => !module.slides || module.slides.length === 0);

                    console.log("Platform Modules:", platformModules);
                    console.log("Staff Modules:", staffModules);

                    const filteredStaffModules = staffModules.filter(module => studentData.studentgroups.modules.includes(module.id.toString()));
                    const filteredPlatformModules = platformModules.filter(module => studentData.studentgroups.modules.includes(module.id.toString()));

                    const allModules = [
                        ...filteredPlatformModules,
                        ...filteredStaffModules.map(module => ({
                            ...module,  // Only pass the slides property
                            slides: module.slides || []  // Ensure slides exist for staff modules
                        }))
                    ];

                    setModules(allModules);
                    setNextModule(
                        allModules.find(
                            (module) =>
                                !studentData.linked_modules.find((linkedModule) => linkedModule.id === module.id)
                                    ?.completed
                        ) || {}
                    );
                    setIsLoading(false);
                }
            }
        })();
    }, [user]);

    if (isLoading) {
        return <PuffLoader color={"#5D87FF"} loading={true} size={150} id="loader" />;
    }

    return (
        <div id="learndiv" className="px-[2.5vw] gap-x-[2rem] !flex-row !items-start w-full">
            <div id="modulediv" className="w-[65%]">
                <div
                    id="learntitlediv"
                    style={{ backgroundColor: GlobalStyles.lightblue }}
                    className={`p-12 rounded-2xl gap-y-4`}
                >
                    <div id="moduletextdiv" className="!flex-row atleast769:!flex-col">
                        <ModuleIcon />
                        <div className="gap-y-[1.5rem]">
                            <p className="text-4xl font-semibold">
                                Modules to <span style={{ color: GlobalStyles.bluetext }}>Learn</span> how to achieve
                                your goals
                            </p>
                            <p className="text-2xl font-normal self-start" style={{ color: GlobalStyles.graytext }}>
                                Your next step:
                            </p>
                        </div>
                    </div>
                    <ModuleCard module={nextModule} />
                </div>
                <div className="my-6 gap-y-4">
                    {modules.length > 0 &&
                        modules
                            .filter((module) => module.id !== nextModule.id)
                            .map((module, index) => <ModuleCard module={module} key={index} />)}
                </div>
            </div>
            <div id="rightlearndiv" className="w-[30%] gap-y-[5vh] under769:w-full">
                <ProductGoal />
                <RecentTasks />
            </div>
        </div>
    );
}
