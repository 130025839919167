import { useNavigate } from "react-router-dom";
import { GlobalStyles } from "../../GlobalStyles.js";

export default function CreateModule() {
    const navigate = useNavigate();

    const buttonStyle = {
        backgroundColor: GlobalStyles.blue,
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '20px',
        padding: '15px 30px',
        fontSize: '1.2rem',
        width: '100%',
        maxWidth: '250px',
        margin: '15px',
        transition: 'transform 0.2s ease-in-out, background-color 0.3s',

    };

    const containerStyle = {
        backgroundColor: GlobalStyles.lightergray,
        height: '50vh',
        width: '80%', // Set a specific width
        margin: '0 auto', // Center the container horizontally
        borderRadius: '16px', // Rounded corners
        padding: '20px', // Add padding inside the container
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: Add a shadow for depth
    };

    const descriptionStyle = {

        fontSize: '1rem',
        color: GlobalStyles.pastelPurple,
        marginBottom: '10px',
    };

    const handleMouseEnter = (e) => {
        e.target.style.transform = 'scale(1.1)';
        e.target.style.backgroundColor = GlobalStyles.pastelBlue;
    };

    const handleMouseLeave = (e) => {
        e.target.style.transform = 'scale(1)';
        e.target.style.backgroundColor = GlobalStyles.pastelPink;
    };

    return (
        <div className="flex flex-col items-center justify-center" style={containerStyle}>
            <h1>
                Create new Content
            </h1>
            <p style={descriptionStyle}>Start an interactive module from scratch.</p>
            <button
                style={buttonStyle}
                onClick={() => navigate("/createinteractive")}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                Create Module
            </button>
            <p style={descriptionStyle}>Upload an existing file to create a module.</p>
            <button
                style={buttonStyle}
                onClick={() => navigate("/upload-module")}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                Upload Module
            </button>
        </div>
    );
}
