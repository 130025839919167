import { useContext, useEffect, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabase";
import { AuthContext } from "../../App";
import "../../assets/css/admin.css"; // Ensure this import is present to apply styles

export default function Modules() {
    const navigate = useNavigate();
    const [modules, setModules] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const { user } = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            const { data: modules, error: moduleError } = await supabase.from("modules").select("*");
            if (moduleError) {
                console.log("Error fetching modules:", moduleError);
                return;
            }

            const { data: groups, error: groupError } =
                user.role === "Administrator"
                    ? await supabase.from("studentgroups").select("uuid, modules").eq("admin", user.email)
                    : await supabase.from("studentgroups").select("uuid, modules").eq("instructor", user.id);

            if (groupError) {
                console.error("Error fetching student groups:", groupError);
                return;
            }

            const updatedModules = [...modules].map((module) => {
                const isChecked = groups.some((group) => {
                    const groupModules = group.modules ? group.modules.map((id) => id.trim()) : [];
                    return groupModules.includes(module.id.toString());
                });
                return { ...module, checked: isChecked, checked_off: isChecked };
            });

            setModules(updatedModules);
        })();
    }, [user]);

    const handleCheckboxChange = async (moduleId, checked) => {
        const { data: groups, error: groupError } = await supabase
            .from("studentgroups")
            .select("uuid, modules")
            .eq(
                user.role === "Administrator" ? "admin" : "instructor",
                user.role === "Administrator" ? user.email : user.id
            );

        if (groupError) {
            console.error("Error fetching student groups:", groupError);
            return;
        } else {
            const updatedGroups = groups.map((group) => {
                let updatedModules = checked
                    ? [...new Set([...(group.modules || []), moduleId])]
                    : (group.modules || []).filter((id) => id !== moduleId.toString());

                return {
                    ...group,
                    modules: updatedModules,
                    ...(user.role === "Administrator" && {
                        checked: updatedModules.includes(moduleId.toString()) ? true : false,
                    }),
                };
            });

            for (const group of updatedGroups) {
                if (group.uuid) {
                    const { error: updateError } = await supabase
                        .from("studentgroups")
                        .update({ modules: group.modules })
                        .eq("uuid", group.uuid);
                    if (updateError) {
                        console.error(`Error updating group with uuid: ${group.uuid}`, updateError);
                    }
                } else {
                    console.error("Group UUID is undefined for group:", group);
                }
            }
        }

        setModules(modules.map((module) => (module.id === moduleId ? { ...module, checked_off: checked } : module)));
    };

    // Filter modules based on search query
    const filteredModules = modules.filter(module => {
        const title = module.title ? module.title.toLowerCase() : '';
        const description = module.description ? module.description.toLowerCase() : '';
        const query = searchQuery.toLowerCase();

        return title.includes(query) || description.includes(query);
    });

    return (
        <div className="w-full gap-[2vh]">
            <div className="!flex-row !justify-between w-full" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <p className="text-2xl font-semibold" style={{ marginRight: '30px' }}>Modules</p>
                <input
                    type="text"
                    placeholder="Search modules..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-bar" // Use the CSS class
                />
            </div>
            <div className="self-start !items-start gap-[3vh] w-full">
                {filteredModules.map((module) => (
                    <div 
                        key={module.id} 
                        style={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center', 
                            width: '100%', 
                            padding: '10px 0', 
                            borderBottom: '1px solid #ccc' 
                        }}
                    >
                        <div style={{ flex: 1 }}>
                            <h2 className="font-medium">{module.title}</h2>
                            <h3 className="font-normal">{module.description}</h3>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={module.checked_off}
                                    onChange={(e) => handleCheckboxChange(module.id, e.target.checked)}
                                />
                                Module Added
                            </label>
                        </div>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <button
                                style={{ backgroundColor: "#5D87FF", color: "white" }}
                                onClick={() => navigate(`/moduletable/${module.id}`)}
                            >
                                Check Results
                            </button>
                            {module.slides && module.slides.length > 0 ? (
                                <button
                                    style={{ backgroundColor: "#D3D3D3", color: "black" }}
                                    onClick={() => navigate(`/createinteractive/${module.id}`)}
                                >
                                    Edit
                                </button>
                            ) : (
                                <button
                                    style={{ backgroundColor: "#D3D3D3", color: "black" }}
                                    onClick={() => navigate(`/uploadmodule/${module.id}`)}
                                >
                                    Edit
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className="button-container" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                <button
                    style={{ backgroundColor: "#5D87FF", color: "white", marginRight: '10px' }}
                    onClick={() => navigate(`/createmodule`)}
                >
                    Create Module
                </button>
            </div>
        </div>
    );
}
