import { ReactComponent as BlueDot } from "../../assets/icons/bluedot.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/arrow-right.svg";
import { GlobalStyles } from "../../GlobalStyles";
import { useState, useEffect } from "react";

export default function DownloadCard({ module, moduleId }) {
    const [fileUrl, setFileUrl] = useState(null);
    const [isFileVisible, setIsFileVisible] = useState(false);
    const moduleName = module.toLowerCase().replace(/\s+/g, '_');
    const assignment = moduleId + "_" + moduleName;

    useEffect(() => {
        const fetchFileUrl = () => {
            const fileName = `${assignment}`;
            const fileBaseUrl = "https://f005.backblazeb2.com/file/acewisefiles/assignments/";
            const fileExtension = ".docx"; // Change to .pdf if files are PDFs
            let constructedFileUrl = `${fileBaseUrl}${fileName}${fileExtension}`;

            // Update the constructedFileUrl for .docx files to use Google Docs Viewer
            if (fileExtension === ".docx") {
                constructedFileUrl = `https://docs.google.com/gview?url=${constructedFileUrl}&embedded=true`;
            }

            setFileUrl(constructedFileUrl);
            console.log(`File URL set to ${constructedFileUrl}`);
        };

        fetchFileUrl();
    }, [assignment]);

    const toggleFileVisibility = () => {
        setIsFileVisible(!isFileVisible);
    };

    const handleDownload = () => {
        const fileName = `${assignment}`;
        const fileBaseUrl = "https://f005.backblazeb2.com/file/acewisefiles/assignments/";
        const fileExtension = ".docx";
        const fileUrl = `${fileBaseUrl}${fileName}${fileExtension}`;

        // Directly downloading the file using the constructed URL
        window.location.href = fileUrl;
        console.log(`Downloading file from ${fileUrl}...`);
    };

    const buttonStyle = {
        backgroundColor: GlobalStyles.lightergray,
        color: 'black',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '12px',
        padding: '10px 20px',
        marginBottom: '10px',
    };

    return (
        <div className="rounded-2xl flex-col gap-x-[2rem] w-full"
            style={{ backgroundColor: GlobalStyles.lightblue, padding: '1rem' }}
        >
            <div className="left-side-design-element"></div>

            <button
                className="rounded-2xl py-2 px-4 mb-4"
                style={buttonStyle}
                onClick={toggleFileVisibility}
            >
                {isFileVisible ? 'Hide File' : 'View File'}
            </button>

            {isFileVisible && fileUrl && (
                <iframe
                    title="filedisplay"
                    src={fileUrl}
                    width="100%"
                    height="600px"
                    style={{ border: "none" }}
                ></iframe>
            )}

            <button
                className="rounded-2xl py-2 px-4"
                style={buttonStyle}
                onClick={handleDownload}
            >
                Download Assignment
            </button>

            {!fileUrl && <p>Loading file...</p>}

            <div className="right-side-design-element"></div>
        </div>
    );
}
