import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../../supabase";
import { AuthContext } from "../../App";
import { toastError, toastSuccess } from "../../GlobalFunctions";
import { GlobalStyles } from "../../GlobalStyles"; // Assuming GlobalStyles is imported
import { uploadFileToBackblaze } from '../../GlobalFunctions';
import { checkFileExistsInBackblaze, deleteFileFromBackblaze } from '../../GlobalFunctions'; // Added imports

export default function UploadModule() {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const { moduleId } = useParams(); // Access the moduleId parameter

    const [module, setModule] = useState({
        title: "",
        description: "",
        link: "",
        assignments: [],
    });

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [moduleName, setModuleName] = useState('');
    const [localModuleId, setLocalModuleId] = useState(moduleId || null); // Use a different name for the state variable

    useEffect(() => {
        if (localModuleId) {
            // Fetch the module data including assignments if moduleId is present
            const fetchModule = async () => {
                const { data, error } = await supabase
                    .from("modules")
                    .select("title, description, link, assignments")
                    .eq("id", localModuleId)
                    .single();

                if (error) {
                    console.error("Error fetching module:", error);
                    toastError("Error loading module, please try again later");
                } else {
                    setModule((prevModule) => ({
                        ...prevModule,
                        title: data.title || "",
                        description: data.description || "",
                        link: data.link || "",
                        assignments: data.assignments || [], // Ensure assignments are loaded
                    }));
                }
            };

            fetchModule();
        }
    }, [localModuleId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setModule((prevModule) => ({
            ...prevModule,
            [name]: value,
        }));
    };

    const addSection = () => {
        setModule((prevModule) => ({
            ...prevModule,
            assignments: [...prevModule.assignments, { sections: [{ title: "", questions: [] }] }],
        }));
    };

    const addQuestion = (sectionIndex, assignmentIndex) => {
        const newAssignments = [...module.assignments];
        newAssignments[assignmentIndex].sections[sectionIndex].questions.push({ text: "", type: "freeresponse" });
        setModule((prevModule) => ({
            ...prevModule,
            assignments: newAssignments,
        }));
    };

    const handleSectionChange = (index, value, assignmentIndex) => {
        const newAssignments = [...module.assignments];
        newAssignments[assignmentIndex].sections[index].title = value;
        setModule((prevModule) => ({
            ...prevModule,
            assignments: newAssignments,
        }));
    };

    const handleQuestionChange = (sectionIndex, questionIndex, value, assignmentIndex) => {
        const newAssignments = [...module.assignments];
        newAssignments[assignmentIndex].sections[sectionIndex].questions[questionIndex].text = value;
        setModule((prevModule) => ({
            ...prevModule,
            assignments: newAssignments,
        }));
    };

    const handleAssignmentTitleChange = (assignmentIndex, value) => {
        const newAssignments = [...module.assignments];
        newAssignments[assignmentIndex].title = value;
        setModule((prevModule) => ({
            ...prevModule,
            assignments: newAssignments,
        }));
    };

    const addAssignment = () => {
        setModule((prevModule) => ({
            ...prevModule,
            assignments: [
                ...prevModule.assignments,
                { title: "", sections: [{ title: "", questions: [] }], answers: {} }
            ],
        }));
    };

    const handleSubmitAndUpload = async (e) => {
        e.preventDefault();

        if (!module.title.trim()) {
            setUploadStatus('Module title is required.');
            console.error('Module title is required.');
            return;
        }

        try {
            let existingAssignments = [];

            // Fetch existing assignments if updating an existing module
            if (localModuleId) {
                const { data, error } = await supabase
                    .from("modules")
                    .select("assignments")
                    .eq("id", localModuleId)
                    .single();

                if (error) {
                    console.error("Error fetching existing assignments:", error);
                    toastError("Error loading existing assignments, please try again later");
                    return;
                }

                existingAssignments = data?.assignments || [];
            }

            // Merge existing answers with the current module data
            const mergedAssignments = module.assignments.map((assignment, index) => {
                const existingAssignment = existingAssignments[index] || {};
                return {
                    title: assignment.title || existingAssignment.title || "", // Include assignment title
                    sections: assignment.sections.map((section, sectionIndex) => ({
                        ...section,
                        questions: section.questions.map((question, questionIndex) => ({
                            ...question,
                            // Ensure any existing answers are preserved
                            answer: existingAssignment.sections?.[sectionIndex]?.questions?.[questionIndex]?.answer || question.answer || ""
                        }))
                    })),
                    answers: existingAssignment.answers || assignment.answers || {}, // Preserve existing answers
                };
            });

            const moduleData = {
                title: module.title,
                description: module.description,
                link: module.link,
                assignments: mergedAssignments,
            };

            // Step 1: Create or update the module in Supabase
            let response;
            if (localModuleId) {
                response = await supabase.from("modules").update(moduleData).eq("id", localModuleId).select();
            } else {
                response = await supabase.from("modules").insert([moduleData]).select();
            }

            const { data, error } = response;

            if (error) {
                console.error("Error saving module:", error);
                toastError("Error saving module, please try again later");
                return;
            }

            const createdModule = data && data[0];
            if (!createdModule) {
                console.error("No module data returned from Supabase.");
                toastError("Error saving module, please try again later");
                return;
            }

            // Step 2: Upload the file to Backblaze if a new file is selected
            if (selectedFile) {
                console.log('File selected:', selectedFile.name);

                const moduleId = createdModule.id;
                const fileExtension = selectedFile.name.split('.').pop();
                const assignment = `${moduleId}_${module.title.toLowerCase().replace(/\s+/g, '_')}.${fileExtension}`;
                const fileName = `assignments/${assignment}`;


                // Upload the file to Backblaze
                const uploadResult = await uploadFileToBackblaze(selectedFile, fileName, 'assignments');
                if (uploadResult) {
                    console.log('File uploaded successfully:', uploadResult);
                    setUploadStatus('File uploaded successfully.');
                } else {
                    console.error('Upload failed: No result returned.');
                    setUploadStatus('Upload failed.');
                }
            } else {
                setUploadStatus('Module saved without file upload.');
            }

            toastSuccess("Module saved successfully");
            navigate("/modules");
        } catch (error) {
            console.error('Error during save process:', error);
            setUploadStatus('Save failed: ' + error.message);
            toastError("Error during save process, please try again later");
        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Upload Module</h1>
            <form onSubmit={handleSubmitAndUpload} style={styles.form}>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Title</label>
                    <input
                        type="text"
                        name="title"
                        value={module.title}
                        onChange={handleInputChange}
                        required
                        style={styles.input}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Description</label>
                    <textarea
                        name="description"
                        value={module.description}
                        onChange={handleInputChange}
                        required
                        style={styles.textarea}
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Link</label>
                    <input
                        type="url"
                        name="link"
                        value={module.link}
                        onChange={handleInputChange}
                        style={styles.input}
                    />
                </div>
                <div>
                    <h2 style={styles.subHeader}>Assignments</h2>
                    {module.assignments.length > 0 ? (
                        module.assignments.map((assignment, assignmentIndex) => (
                            <div key={assignmentIndex} style={styles.assignmentGroup}>
                                <input
                                    type="text"
                                    placeholder="Assignment Title"
                                    value={assignment.title}
                                    onChange={(e) => handleAssignmentTitleChange(assignmentIndex, e.target.value)}
                                    required
                                    style={styles.input}
                                />
                                {assignment.sections.map((section, sectionIndex) => (
                                    <div key={`${assignmentIndex}-${sectionIndex}`} style={styles.sectionGroup}>
                                        <input
                                            type="text"
                                            placeholder="Section Title"
                                            value={section.title}
                                            onChange={(e) => handleSectionChange(sectionIndex, e.target.value, assignmentIndex)}
                                            required
                                            style={styles.input}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => addQuestion(sectionIndex, assignmentIndex)}
                                            style={styles.addButton}
                                        >
                                            Add Question
                                        </button>
                                        {section.questions.map((question, questionIndex) => (
                                            <div key={questionIndex} style={styles.questionGroup}>
                                                <input
                                                    type="text"
                                                    placeholder="Question Text"
                                                    value={question.text}
                                                    onChange={(e) =>
                                                        handleQuestionChange(sectionIndex, questionIndex, e.target.value, assignmentIndex)
                                                    }
                                                    required
                                                    style={styles.input}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <p></p>
                    )}
                    <button
                        type="button"
                        onClick={addAssignment}
                        style={styles.grayButton}
                    >
                        Add Assignment
                    </button>
                </div>
                <input type="file" onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                        console.log('File selected:', file.name);
                        setSelectedFile(file);
                    }
                }} />

                <button onClick={handleSubmitAndUpload} style={styles.blueButton}>
                    Create Module
                </button>
                {uploadStatus && <p>{uploadStatus}</p>}
            </form>
        </div>
    );
}

const styles = {
    container: {
        padding: '20px',
        maxWidth: '600px',
        margin: '0 auto',
        backgroundColor: '#f0f4f8',
        borderRadius: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    header: {
        textAlign: 'center',
        color: '#333',
        marginBottom: '20px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formGroup: {
        marginBottom: '20px',
    },
    label: {
        marginBottom: '5px',
        color: '#555',
    },
    input: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
        fontSize: '16px',
    },
    textarea: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
        fontSize: '16px',
        minHeight: '100px',
    },
    subHeader: {
        color: '#333',
        marginBottom: '10px',
    },
    sectionGroup: {
        marginBottom: '20px',
        padding: '10px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    questionGroup: {
        marginBottom: '10px',
    },
    addButton: {
        padding: '8px 16px',
        backgroundColor: '#007BFF',
        color: 'white',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        marginBottom: '10px',
    },
    grayButton: {
        padding: '8px 16px',
        backgroundColor: GlobalStyles.lightgray, // Use the gray color from GlobalStyles
        color: 'white',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        marginBottom: '10px',
    },
    blueButton: {
        padding: '10px 20px',
        backgroundColor: GlobalStyles.blue, // Use the blue color from GlobalStyles
        color: 'white',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
};
