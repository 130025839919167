import React, { useState, useEffect, useContext, useRef } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import SignupScreen from "../../screens/SignUpScreen.jsx";
import Onboarding from "../../screens/Onboarding.jsx";
import { supabase } from "../../supabase.js";
import Header from "./Header.jsx";
import { useMediaQuery } from "react-responsive";
import CreateGroup from "../../screens/CreateGroup.jsx";
import Surveys from "../../screens/Surveys.jsx";
import LoginScreen from "../../screens/LoginScreen.jsx";
import { checkMetadata, checkOnboarding, getTableName } from "../../GlobalFunctions.js";
import AdminHome from "../home/AdminHome.jsx";
import StudentHome from "../home/StudentHome.jsx";
import SuperAdminHome from "../home/SuperAdminHome.jsx";
import OneSignal from "react-onesignal";
import MentorMatching from "../admin/MentorMatching.jsx";
import { AuthContext } from "../../App.js";
import Connect from "../student/Connect.jsx";
import Sidebar from "./Sidebar.jsx";
import Opportunities from "../../screens/Opportunities.jsx";
import Learn from "../../screens/Learn.jsx";
import Module from "../../screens/Module.jsx";
import StudentSurvey from "../../screens/students/StudentSurvey.jsx";
import EditSurvey from "../../screens/admin/EditSurvey.jsx";
import SurveyTable from "../../screens/admin/SurveyTable.jsx";
import ViewSurvey from "../../screens/admin/ViewSurvey.jsx";
import Modules from "../../screens/admin/Modules.jsx";
import ModuleTable from "../../screens/admin/ModuleTable.jsx";
import ViewModule from "../../screens/admin/ViewModule.jsx";
import Settings from "../../screens/Settings.jsx";
import Assignment from "../../screens/students/Assignment.jsx";
import ResetPassword from "../../screens/global/ResetPassword.jsx";
import StudentPortfolio from "../../screens/global/StudentPortfolio.jsx";
import InstructorHome from "../home/InstructorHome.jsx";
import CreateInteractive from "../../screens/global/CreateInteractive.jsx";
import CreateModule from "../../screens/global/CreateModule.jsx";
import InstructorModules from "../../screens/instructors/InstructorModules.jsx";
import DailyReport from "../../screens/DailyReport.jsx";
import ViewAssignment from "../../screens/admin/ViewAssignment.jsx";
import ViewDailyReports from "../../screens/admin/ViewDailyReports.jsx";
import InsDailyReport from "../../screens/admin/InsDailyReport.jsx";
import Logout from "./Logout.jsx";
import ModuleAnswers from "../../screens/global/ModuleAnswers.jsx";
import UploadModule from "../../screens/global/UploadModule.jsx";
import Profile from "../../screens/global/Profile.jsx"; // Import the Profile component



export default function Routing() {
    const [isLoading, setIsLoading] = useState(true);
    const [onboardingCheck, setOnboardingCheck] = useState(false);
    // session denotes whether user is logged in
    const [session, setSession] = useState(null);
    const [sessionchecked, setSessionChecked] = useState(false);
    const [rolechecked, setRoleChecked] = useState(false);
    const is768OrLess = useMediaQuery({ query: "(max-width: 768px)" });
    const [sidetabvisible, setTabVisible] = useState(!is768OrLess);
    const [onboardingdone, setOnboardingDone] = useState(false);
    const { user, setUser, curpath, logout } = useContext(AuthContext);

    useEffect(() => {
        async function initializeSignal() {
            if (!OneSignal.initialized) {
                await OneSignal.init({
                    appId: process.env.REACT_APP_SIGNAL_ID,
                    allowLocalhostAsSecureOrigin: true,
                })
                    .then(() => {
                        OneSignal.Slidedown.promptPush();
                    })
                    .catch((e) => {
                        console.error("error in onesig:", e);
                    });
            } else {
                console.log("already initialized");
            }
        }
        async function initialCheckSession() {
            const {
                data: { session },
            } = await supabase.auth.getSession();
            setSession(session);
            setSessionChecked(true);
        }

        initialCheckSession();
        if (process.env.NODE_ENV === "production") {
            initializeSignal();
        }

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
            setSessionChecked(true);
        });

        return () => subscription.unsubscribe();
    }, []);

    const userRef = useRef(user);
    const listenerSetRef = useRef(false);

    useEffect(() => {
        userRef.current = user;
        if (user && !listenerSetRef.current) {
            // console.log("setting up channel");
            const table = getTableName(user?.role);
            const filter = "email=eq." + user.email;
            // const filter = 'id=eq.7'
            // console.log("table:", table, "\n\nfilter:", filter)
            supabase
                .channel(user?.email)
                .on("postgres_changes", { event: "*", schema: "public", table, filter }, async (payload) => {
                    // console.log('Change received!', payload,
                    //   "\n\nuser currently:", userRef.current
                    // )
                    setUser({ ...payload.new, role: userRef.current.role });
                })
                .subscribe();
            listenerSetRef.current = true;
        } else if (!user) {
            // console.log("removing all channels");
            supabase.removeAllChannels();
            listenerSetRef.current = false;
        }
    }, [user]);

    // useEffect(() => {
    //   console.log("session changed to:", session)
    //   console.log("user changed to:", user)
    // }, [session, user])

    useEffect(() => {
        // Check session and role to determine if onboarding is filled
        if (session && !user?.role) {
            setIsLoading(true);
            (async function getRole() {
                const metadata = await checkMetadata(setSession, setUser);
                // console.log("metadata in getRole:", metadata);
                if (metadata.role && metadata.email) {
                    if (metadata.role === "SuperAdmin") {
                        setUser({ role: metadata.role, email: metadata.email });
                    } else {
                        const { data, error } = await supabase
                            .from(getTableName(metadata.role))
                            .select("*")
                            .eq("email", metadata.email)
                            .single();
                        if (error) {
                            console.error("Error getting student data:", error);
                            if (error.code === "PGRST116") {
                                // Allow user to proceed to onboarding if their row is missing
                                setUser({ role: metadata.role, email: metadata.email });
                                <Route path="/onboarding" element={<Navigate to={"/"} />} />;
                            } else {
                                await logout();
                            }
                        } else {
                            if (process.env.NODE_ENV === "development")
                                console.log("user:", { ...data, role: metadata.role });
                            setUser({ ...data, role: metadata.role });
                        }
                    }
                }
                setRoleChecked(true);
                // console.log("getRole => email:", metadata.email, "role:", metadata.role);
                let onboardingdone;
                if (metadata.role && metadata.email) {
                    onboardingdone = await checkOnboarding(metadata.role, metadata.email);
                }
                // navigate to onboarding if onboarding is not done
                if (onboardingdone) {
                    setOnboardingDone(true);
                } else {
                    <Route path="/onboarding" element={<Navigate to={"/"} />} />;
                }
                setOnboardingCheck(true);
                setIsLoading(false);
            })();
        }

        // console.log("sessionchecked:", sessionchecked, "session:", session, "rolechecked:", rolechecked, "role:", role);
        if (sessionchecked && (session ? rolechecked && onboardingCheck : true)) {
            setIsLoading(false);
        }
    }, [onboardingCheck, user, rolechecked, session, sessionchecked]);

    // const props = useSpring({
    //   from: {
    //     left: sidetabvisible ? (is1000 ? '-13%' : '-20%') : '0vw',
    //     width: sidetabvisible ? '100%' : '74.8%'
    //   },
    //   to: {
    //     left: sidetabvisible ? '0vw' : (is1000 ? '-13%' : '-20%'),
    //     width: sidetabvisible ? '74.8%' : '100%'
    //   },
    // })

    if (isLoading) {
        return <PuffLoader color={"#5D87FF"} loading={true} size={150} id="loader" />;
    }

    // console.log("current route:", onboardingdone ? "Main App" : session ? "Onboarding" : "Login/Signup");
    return (
        <BrowserRouter>
            {/* if onboarding isn't done, only show the onboarding screen */}
            {onboardingdone ? (
                <div className="!flex-row !items-start w-full">
                    {session && user?.role && curpath !== "Resetpassword" && (
                        <Sidebar sidetabvisible={sidetabvisible} setTabVisible={setTabVisible} role={user?.role} />
                    )}
                    <div
                        id="mainrightdiv"
                        // ${sidetabvisible && 'atleast769:pr-[2vw]'}
                        className={`${session && user?.role && "relative"} pt-4 mb-[5vh] pl-[2vw] atleast769:!pr-[2vw]
              ${curpath === "Resetpassword" && "perfectcenter"}
            `}
                        style={{
                            width: sidetabvisible ? (is768OrLess ? "100%" : "75vw") : "100%",
                            // paddingInline: !sidetabvisible && '2vw',
                            position: !sidetabvisible && "absolute",
                            left: !sidetabvisible && "0vw",
                            height: curpath === "Assignment" ? "calc(97vh - 1rem)" : "auto",
                            minHeight: "100%",
                        }}
                    >
                        {session && user?.role && curpath !== "Resetpassword" && (
                            <Header sidetabvisible={sidetabvisible} setTabVisible={setTabVisible} user={user} />
                        )}
                        <Routes>
                            {/* show admin home if role is Administrator, and Home if not */}
                            <Route
                                exact
                                path="/"
                                element={
                                    session ? (
                                        user?.role === "Administrator" ? (
                                            // <SuperAdminHome />
                                            <AdminHome
                                                email={user?.email}
                                                name={user?.name}
                                                sidetabvisible={sidetabvisible}
                                            />
                                        ) : user?.role === "Student" ? (
                                            <StudentHome email={user?.email} name={user?.name} />
                                        ) : user?.role === "SuperAdmin" ? (
                                            <SuperAdminHome sidetabvisible={sidetabvisible} />
                                        ) : (
                                            <InstructorHome sidetabvisible={sidetabvisible} />
                                        )
                                    ) : (
                                        !session && <SignupScreen />
                                    )
                                }
                            />
                            <Route exact path="/signin" element={session ? <Navigate to={"/"} /> : <LoginScreen />} />
                            {session && <Route exact path="/resetpassword" element={<ResetPassword />} />}
                            {session && <Route exact path="/studentportfolio/:studentId/:studentEmail" element={<StudentPortfolio />} />}
                            {session && <Route exact path="/home" element={<Navigate to={"/"} />} />}
                            {session && <Route path="/signin" element={<Navigate to={"/"} />} />}
                            {session && <Route exact path="/learn" element={<Learn />} />}
                            {session && <Route exact path="/opportunities" element={<Opportunities />} />}
                            {session && <Route exact path="/module" element={<Module />} />}
                            <Route path="/createmodule" element={<CreateModule />} />
                            {session && (
                                <Route
                                    exact
                                    path="/createinteractive"
                                    element={<CreateInteractive sidetabvisible={sidetabvisible} />}
                                />
                            )}
                            {session && (
                                <Route
                                    exact
                                    path="/createinteractive/:moduleId"
                                    element={<CreateInteractive sidetabvisible={sidetabvisible} />}
                                />
                            )}
                            {session && <Route exact path="/dailyreport" element={<DailyReport />} />}
                            <Route
                                path="/assignment"
                                element={(session && user?.role) === "Student" ? <Assignment /> : <Navigate to="/" />}
                            />
                            {session && (
                                <Route
                                    exact
                                    path="/settings"
                                    element={<Settings sidetabvisible={sidetabvisible} setSession={setSession} />}
                                />
                            )}
                            <Route
                                path="/matching"
                                element={
                                    session ? (
                                        user?.role === "Administrator" ? (
                                            <CreateGroup />
                                        ) : (
                                            <Navigate to={"/"} />
                                        )
                                    ) : (
                                        <Navigate to={"/"} />
                                    )
                                }
                            />
                            <Route
                                path="/surveys"
                                element={
                                    session ? (
                                        user?.role === "Administrator" || user?.role === "Instructor" ? (
                                            <Surveys sidetabvisible={sidetabvisible} />
                                        ) : (
                                            <Navigate to={"/signin"} />
                                        )
                                    ) : (
                                        <Navigate to={"/"} />
                                    )
                                }
                            />
                            <Route exact path="/connect" element={session ? <Connect /> : <SignupScreen />} />
                            <Route path="/join/studentgroup/:uuid" element={<SignupScreen />} />
                            <Route
                                path="/mentormatching"
                                element={<MentorMatching sidetabvisible={sidetabvisible} />}
                            />
                            <Route path="/survey/:surveyid" element={<StudentSurvey />} />
                            <Route
                                path="editsurvey/:surveyid"
                                element={
                                    session ? (
                                        user?.role === "Administrator" || user?.role === "Instructor" ? (
                                            <EditSurvey />
                                        ) : (
                                            <Navigate to={"/"} />
                                        )
                                    ) : (
                                        <Navigate to={"/"} />
                                    )
                                }
                            />
                            <Route
                                path="/surveytable/:surveyid"
                                element={
                                    session ? (
                                        user?.role === "Administrator" || user?.role === "Instructor" ? (
                                            <SurveyTable />
                                        ) : (
                                            <Navigate to={"/"} />
                                        )
                                    ) : (
                                        <Navigate to={"/"} />
                                    )
                                }
                            />
                            <Route
                                path="/viewsurvey/:surveyid/:studentid"
                                element={
                                    session ? (
                                        user?.role === "Administrator" || user?.role === "Instructor" ? (
                                            <ViewSurvey />
                                        ) : (
                                            <Navigate to={"/"} />
                                        )
                                    ) : (
                                        <Navigate to={"/"} />
                                    )
                                }
                            />
                            <Route
                                path="/modules"
                                element={
                                    session ? (
                                        user?.role === "Administrator" || user?.role === "Instructor" ? (
                                            <Modules />
                                        ) : (
                                            <Navigate to={"/"} />
                                        )
                                    ) : (
                                        <Navigate to={"/"} />
                                    )
                                }
                            />
                            <Route
                                path="/moduletable/:moduleid"
                                element={
                                    session ? (
                                        user?.role === "Administrator" || user?.role === "Instructor" ? (
                                            <ModuleTable />
                                        ) : (
                                            <Navigate to={"/"} />
                                        )
                                    ) : (
                                        <Navigate to={"/"} />
                                    )
                                }
                            />
                            <Route
                                path="/viewmodule/:moduleid/:studentid"
                                element={
                                    session ? (
                                        user?.role === "Administrator" || user?.role === "Instructor" ? (
                                            <ViewModule />
                                        ) : (
                                            <Navigate to={"/"} />
                                        )
                                    ) : (
                                        <Navigate to={"/"} />
                                    )
                                }
                            />
                            <Route
                                path="/instructormodules"
                                element={
                                    session ? (
                                        user?.role === "Instructor" ? (
                                            <InstructorModules />
                                        ) : (
                                            <Navigate to={"/"} />
                                        )
                                    ) : (
                                        <Navigate to={"/"} />
                                    )
                                }
                            />
                            <Route
                                path="/viewmoduleassignment/*"
                                element={session ? <ViewAssignment /> : <Navigate to={"/"} />}
                            />
                            <Route
                                path="/viewdailyreports"
                                element={
                                    session ? (
                                        user?.role === "Administrator" ? (
                                            <ViewDailyReports />
                                        ) : (
                                            <Navigate to={"/"} />
                                        )
                                    ) : (
                                        <Navigate to={"/"} />
                                    )
                                }
                            />
                            <Route
                                path="/dailyreports/*"
                                element={
                                    session ? (
                                        user?.role === "Administrator" ? (
                                            <InsDailyReport />
                                        ) : (
                                            <Navigate to={"/"} />
                                        )
                                    ) : (
                                        <Navigate to={"/"} />
                                    )
                                }
                            />
                            {/* if a user goes to /logout, log them out */}
                            <Route path="/logout" element={session ? <Logout /> : <Navigate to={"/"} />} />
                            <Route path="/moduleanswers/:moduleId/:studentId" element={<ModuleAnswers />} />
                            <Route path="/upload-module" element={<UploadModule />} />
                            <Route path="/uploadmodule/:moduleId" element={<UploadModule />} /> 
                            <Route path="/profile" element={<Profile />} /> {/* Add the Profile route */}
                            <Route path="*" element={<Navigate to={"/"} />} />
                        </Routes>
                    </div>
                </div>
            ) : // when onboarding isnt done, show onboarding if session is true, else show signup and login
            session ? (
                <Routes>
                    <Route
                        path="/onboarding"
                        element={
                            <Onboarding
                                setUser={setUser}
                                setOnboardingDone={setOnboardingDone}
                                passedrole={user?.role}
                            />
                        }
                    />
                    {/* send all routes to onboarding if onboarding isn't done */}
                    <Route path="/logout" element={session ? <Logout /> : <Navigate to={"/"} />} />
                    <Route path="*" element={<Navigate to={"/onboarding"} />} />
                </Routes>
            ) : (
                <Routes>
                    <Route path="/signin" element={<LoginScreen />} />
                    <Route path="/signup" element={<SignupScreen />} />
                    <Route path="*" element={<Navigate to={"/signin"} />} />
                    <Route path="/onboarding" element={<Navigate to={"/"} />} />
                    <Route path="/join/studentgroup/:uuid" element={<SignupScreen />} />
                </Routes>
            )}
        </BrowserRouter>
    );
}